@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500;600;700;800&display=swap");

.change_font {
  font-family: "Roboto", sans-serif;
}

@font-face {
  font-family: payam;
  src: url("./assets/fonts/rudaw.woff2");
}

:root {
  --primary-color: #fff200;
  --secondary-color: #ece32a;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: payam;
  background-color: #18191a;
  color: #fefefe;
  direction: rtl;
}

.paragraph {
  @apply text-gray-300 whitespace-pre-line;
}

.primary-button {
  @apply bg-primary sm:text-base text-sm px-4 py-2 text-black sm:px-6 sm:py-2 rounded duration-300 hover:bg-secondary;
}

.admin-section {
  @apply xl:flex-[0.8] flex-1 mt-28 mb-10 w-full;
}

.flex__start {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex__center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.link_hover {
  transition: 0.3s;
}

.link_hover:hover {
  color: var(--primary-color);
}

::selection {
  background-color: var(--primary-color);
  color: #000000;
}

::-webkit-scrollbar {
  background: #202020;
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background: #3a3b3c;
  border-radius: 5px;
}

label {
  cursor: pointer;
}

.section {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 100px;
  flex-direction: column;
}

.swiper-pagination-bullet {
  background-color: var(--primary-color) !important;
  width: 18px !important;
  height: 18px !important;
}

.change_direction {
  direction: ltr;
}

p {
  white-space: pre-line;
}

@media screen and (max-width: 640px) {
  .section {
    margin-top: 70px;
  }
}

.shadow_inner {
  box-shadow: inset 0px 0px 50px 0 var(--primary-color);
}

.blur__effect {
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

.css-13cymwt-control {
  background-color: #202020 !important;
  color: #777777 !important;
  width: 100%;
}

.css-1nmdiq5-menu {
  background-color: #202020 !important;
  color: #777777 !important;
}

.css-1dimb5e-singleValue {
  color: #777777 !important;
}

.css-b62m3t-container {
  width: 100%;
}

.poster {
  &:hover .poster-content {
    opacity: 1;
  }
}

.hovering_gameSeries {
  transition: 0.3s;

  &:hover .hovered_gameSeries {
    opacity: 1;
    z-index: 2;
  }

  &:hover .blur_hovered_gameSeries {
    opacity: 1;
    z-index: 2;
  }
}

.blur_hovered_gameSeries {
  opacity: 0;
}

.hovered_gameSeries {
  transition: 0.3s;
  opacity: 0;
  z-index: 0;
}

.newsHovering {
  &:hover {
    .newsHovered img {
      transform: scale(1.1);
    }
  }
}

.newsHovered img {
  transition: 0.3s;
}

.yellowColor {
  background: linear-gradient(to bottom, #fff200, #00000050);
}

.greenColor {
  background: linear-gradient(to bottom, #20e87d, #00000050);
}

.redColor {
  background: linear-gradient(to bottom, #ff3334, #00000050);
}

.blueColor {
  background: linear-gradient(to bottom, #66c0f4, #00000050);
}

.staff_content:hover .staff_content_img {
  transform: scale(1.1);
}

.disabled {
  opacity: 0.5;
  cursor: auto;
}

.pagination::-webkit-scrollbar {
  display: none;
}


@media screen and (max-width: 400px) {
  .poster-img {
    height: 265px;
  }
}