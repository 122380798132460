.swiper-button-prev {
  left: 15% !important;
}
.swiper-button-next,
.swiper-button-prev {
  top: 40% !important;
  font-size: 16px;
  height: 40px !important;
  width: 40px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background: linear-gradient(180deg, #ffffff20 0%, #ffffff80 100%);
  border-radius: 5px;
}
.swiper-button-next {
  right: 96% !important;
}
.poster {
  &:hover .poster-content {
    opacity: 1;
  }
}

@media screen and (max-width: 1050px) {
  .swiper-button-next {
    right: 94% !important;
  }
}
@media screen and (max-width: 650px) {
  .swiper-button-next {
    right: 90% !important;
  }
}
@media screen and (max-width: 450px) {
  .swiper-button-next {
    right: 88% !important;
  }
}
