.swiper-pagination-progressbar {
  background-color: #2b2b2b !important;
}
.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: var(--primary-color) !important;
}
.swiper-horizontal > .swiper-pagination-progressbar,
.swiper-pagination-progressbar.swiper-pagination-horizontal,
.swiper-pagination-progressbar.swiper-pagination-vertical.swiper-pagination-progressbar-opposite,
.swiper-vertical
  > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
  height: 5px !important;
  bottom: 0 !important;
  top: unset !important;
}

.swiper-wrapper {
  padding-bottom: 10px;
}

.swiper-horizontal > .swiper-scrollbar,
.swiper-scrollbar.swiper-scrollbar-horizontal {
  background-color: #4c4740 !important;
  height: 4px !important;
}
.swiper-scrollbar-drag {
  background: #81817f !important;
}
